// @flow
import * as R from 'ramda';

import React, { FC, useMemo, } from 'react';
import { Redirect, useLocation, } from 'react-router-dom';
import { Container, Card, Row, Col, Image, } from 'react-bootstrap';
import { type PaymentSuccess, } from '../methods/types';
import styled from 'styled-components';
import { useSelector, } from 'react-redux';
import { UserRedux, } from 'state/reducers';
import { currencyFormat, } from 'utilities/stringUtils';
import { type Firm, getLocalDateTime, } from 'domain/firm';
import { Colors, Images } from 'assets/index';


const StyledCard = styled(Card)`
  padding: 15px 20px 15px;
`;

const StyledCardHeader = styled(Card.Header)`
  background: transparent;
  padding: 0px;
`;

// connect redux
const useConnect = () => {
  // mapState
  const firm: Firm = useSelector(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getFirm));
  const user = useSelector(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getUser));
  const mapState = {
    firm,
    user,
  };

  return {
    ...mapState,
  };
};

const AccessPage: FC = () => {

  const { firm, user, } = useConnect();

  // get query params from url
  const location = useLocation();
  const paymentSuccess: PaymentSuccess | undefined = location.state && location.state.paymentSuccess;
  const systemDate = user && user.systemDateFormat ? user.systemDateFormat : null;

  const CardText = ({ children }) => {
    return <Card.Text className="my-3 px-3 d-flex flex-row justify-content-between align-items-center">
      {children}
    </Card.Text>
  }

  const Footer = useMemo(() => {
    const firmName = firm.firmName;

    return <Card.Text className="my-5 px-4 align-items-center">
      You have successfully submitted a payment to <b>{firmName}</b>. You will receive an email confirming the status of your payment soon.
 </Card.Text>

  }, [firm,]);

  // redirect to home if no pendingPayment provided in nav params
  if (!paymentSuccess) {
    return <Redirect to='/' />;
  }
  const { emailList, pmtMethodLabel, amount, transaction, b4tpmtId } = paymentSuccess;
  const { lastName, firstName } = paymentSuccess;
  const { hasSurcharge, surchargePercentage, surchargeAmount, totalAmount } = paymentSuccess;
  const getEmailList = () => {
    const emailListStr = emailList.join('\r\n');
    return emailListStr;
  }

  return (
    <Container className="my-3">
      <Row className="justify-content-center">
        <Col md="5">
          <StyledCard>
            <Card.Body className="p-0">
              <Card.Title className="mt-3 mb-5 text-center">
                <Image className="mb-2" src={Images.payment.checkCircle} />
                <h3><b>{`Payment Confirmation`}</b></h3>
              </Card.Title>

              <CardText>
                {`Amount Paid:`} <b>{currencyFormat(amount.toFixed(2), firm)}</b>
              </CardText>

              {
                hasSurcharge && 
                <>
                <CardText>
                  {`Credit Card Fee ${surchargePercentage ? '(' + surchargePercentage + '%)' : ''}:`} <b>{currencyFormat(surchargeAmount, firm)}</b>
                </CardText>

                <CardText>
                  {`Payment Total:`} <b>{currencyFormat(totalAmount, firm)}</b>
                </CardText>
                </>
              }

              <CardText>
                {`Payment Method:`} <b>{pmtMethodLabel}</b>
              </CardText>

              <CardText> {'Payment Date/Time:'} <b>{getLocalDateTime(systemDate)}</b> </CardText>

              <CardText> {'Name:'} <b> {firstName} {lastName} </b> </CardText>

              <Card.Body className="my-3 px-3 row">
                <Card.Body class="col-sm">
                  {'Receipt sent to:'}
                </Card.Body>
                <Card.Body class="col-sm text-right">
                  <b>{getEmailList()}</b>
                </Card.Body>
              </Card.Body>

              <CardText>
                {`Payment ID:`} <b>{b4tpmtId}</b>
              </CardText>

              {Footer}
              <Card.Text className="d-flex justify-content-end mt-2">
                <Card.Link href="/">{``}</Card.Link>
              </Card.Text>
            </Card.Body>
          </StyledCard>
        </Col>
      </Row>
    </Container>
  );
};

export default AccessPage;
