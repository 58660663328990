// @flow
import * as R from 'ramda';
import { Alert, Button, Card, Col, Container, Row, Spinner, } from 'react-bootstrap';
import { BANK_CARD_TYPE, type BankAccountData, type CardData, PAYMENT_METHOD, } from 'domain/payment';
import { type InvoicePaymentRouteParams, type PaymentData, } from './types';

import { Colors, } from 'assets/index';
import { type DropDownOption, } from 'view/components/Dropdown/types';
import { Dropdown, } from 'view/components';
import { type Firm, type HeadnoteFirmSettings } from 'domain/firm';
import { Link, } from 'react-router-dom';
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { type User, } from 'domain/user';
import { currencyFormat, } from 'utilities/stringUtils';
import styled from 'styled-components';
import HeadnoteECheck from './_components/Headnote/HeadnoteECheckPlaid';
import HeadnoteECheckARNumbers from './_components/Headnote/HeadnoteECheckARNumbers';
import HeadnoteCreditCard from './_components/Headnote/HeadnoteCreditCard';
import { useHistory, useLocation, } from "react-router-dom";
import { HEADNOTE_PAYMENT_METHODS } from 'domain/payment';
import { RequiredLabel } from './_components/Headnote/RequiredLabel';
import ErrorMsg from './_components/Headnote/ErrorMsg';
import { type ClientHeadnoteInfo } from 'domain/client';

const trackMixpanelEventSaveCheckBox = () => { window.mixpanel.track("b4tp_client_portal_save_personal_use"); }
const trackMixpanelEventTOS = () => { window.mixpanel.track("b4tp_client_portal_terms_and_conditions"); }
const trackMixpanelEventAddRecipient = () => { window.mixpanel.track("b4tp_client_portal_add_a_recipient"); }
const trackMixpanelEventSubmitPayment = () => { window.mixpanel.track("b4tp_client_portal_submit_payment"); }
const trackMixpanelEventPmtMethodDropdown = () => { window.mixpanel.track("b4tp_client_portal_payment_method_dropdown"); }
const trackMixpanelEventCreditCard = () => { window.mixpanel.track("b4tp_client_portal_credit_card"); }
const trackMixpanelEventPlaid = () => { window.mixpanel.track("b4tp_client_portal_secure_bank_login"); }
const trackMixpanelEventArNumber = () => { window.mixpanel.track("b4tp_client_portal_account_routing_numbers"); }

const StyledCard = styled(Card)`
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 25px 20px;
  background: white;
  border-radius: 0px;
  border: solid 1px ${Colors.gainsBoro};
  box-shadow: 1px 1px 1px ${Colors.lightGrey};
  z-index: 999; /* Paypal button hide dropdown option so need to put z-index */
`;

const StyleContainer = styled(Container)`
  flex: 1;
  padding-top: 40px;
`;

const StyledHr = styled.hr`
  margin: 5px 0px 20px;
`;

type Props = {
  firm: Firm,
  selectedPaymentBankCard?: PaymentData,
  setSelectedPaymentBankCard: Function,
  savedPaymentMethods: Array<PaymentData>,
  isSubmitting: boolean,
  user?: User,
  submitPayment: Function,
  invoicePayment: InvoicePaymentRouteParams,
  goBack: Function,
  error: object,
  loading: boolean,
  vgsForm: object,
  vgsErrors: object,
  headnoteFirmSettings: HeadnoteFirmSettings,
  clientHeadnoteInfo: ClientHeadnoteInfo
};

const getBankCardOptionValue = (c: PaymentData): PaymentData => c;
type HeadnotePaymentMethodOption = {
  label: String;
  type: String;
  id?: String;
  isEnabledForPersonalUse?: Boolean;
}
const newPaymentMethodOptions: HeadnotePaymentMethodOption[] = [
  {
    label: 'Credit Card',
    type: HEADNOTE_PAYMENT_METHODS.NEWCARD,
  }, {
    label: 'eCheck via Secure Bank Login',
    type: HEADNOTE_PAYMENT_METHODS.PLAID
  }, {
    label: 'eCheck via Account & Routing Numbers',
    type: HEADNOTE_PAYMENT_METHODS.ARNUMBER,
  }
];


const getSavedPaymentMethodOptions = (data): HeadnotePaymentMethodOption[] => {
  return data.map(paymentMethod => {
    let label, type, pmtMethodLabel;

    if (paymentMethod.last4) { //Credit Card
      label = <>Credit Card ending in <strong>{paymentMethod.last4}</strong></>
      pmtMethodLabel = `Credit Card x${paymentMethod.last4}`;
      type = HEADNOTE_PAYMENT_METHODS.SAVED_CC;
    } else { //eCheck
      label = <>eCheck - {paymentMethod.account_name}</>;
      pmtMethodLabel = paymentMethod.account_name;
      type = HEADNOTE_PAYMENT_METHODS.SAVED_ECHECK;
    }

    return {
      id: paymentMethod.id,
      isEnabledForPersonalUse: paymentMethod.isEnabledForPersonalUse ?? false,
      type,
      label,
      pmtMethodLabel
    }
  });
};

const formatGroupLabel = data => <span><strong>{data.label}</strong></span>;

const dropdownStyles = {
  groupHeading: () => ({
    backgroundColor: Colors.solitude,
    padding: '2% 3%'
  })
};

/**
 * Main component
 *
 * @param {(Props)} props
 * @returns
 */
const PaymentMethodsHeadnoteV = ({ goBack, loading, error, submitPayment, vgsForm, headnoteARNumberFormRef, savedPaymentMethods, vgsErrors, headnoteFirmSettings, clientHeadnoteInfo, ...props }: Props) => {
  const allowGuest = R.path(['portalSettings', 'allowGuests',], props.firm);
  const location = useLocation();
  const history = useHistory();
  const [isPlaidConnectionFailed, setIsPlaidConnectionFailed] = useState(false);
  const paymentMethod = props.selectedPaymentBankCard ? props.selectedPaymentBankCard.type : null;
  const defaultSaveMethodStatus = true;
  const defaultSaveForFirmUseState = false;
  const userEmail = props.user ? props.user.email : "";
  const [recipientList, setRecipientList] = useState([userEmail]);
  const [showEmailError, setShowEmailError] = useState(false);
  const maxRecipientSize = 5;
  const showAddButton = recipientList.length < maxRecipientSize;
  const [showSavePaymentMethodCheckboxes, setShowSavePaymentMethodCheckboxes] = useState(true);

  const hasUnallowedPaymentMethods = !headnoteFirmSettings?.credit_card_processing_allowed || !headnoteFirmSettings?.debit_card_processing_allowed || !headnoteFirmSettings.ach_processing_allowed;
  const hasNoAllowedPaymentMethods = !headnoteFirmSettings?.credit_card_processing_allowed && !headnoteFirmSettings?.debit_card_processing_allowed && !headnoteFirmSettings.ach_processing_allowed;

  const hasCardTransactionLimit = !!headnoteFirmSettings?.card_transaction_limit_in_cents;
  const showCardLimitMessage = hasCardTransactionLimit && (headnoteFirmSettings.credit_card_processing_allowed || headnoteFirmSettings.debit_card_processing_allowed);

  const hasAchTransactionLimit = !!headnoteFirmSettings?.ach_transaction_limit_in_cents;
  const showAchLimitMessage = hasAchTransactionLimit && headnoteFirmSettings.ach_processing_allowed;

  const groupedOptions = [
    {
      label: 'SAVED METHODS',
      options: getSavedPaymentMethodOptions(savedPaymentMethods),
    },
    {
      label: 'NEW METHODS',
      options: newPaymentMethodOptions
    },
  ];

  const updateSavePaymentMethodState = (state) => {
    const locationState = location.state;
    locationState.savePaymentMethod = state;
    history.replace(location.pathname, locationState);
  }

  const updateSaveForFirmUseState = (state) => {
    const locationState = location.state;
    locationState.saveForFirmUse = state;
    history.replace(location.pathname, locationState);
  }

  const updateEmail = (e, index) => {
    const newEmail = e.target.value;

    if (index === 0) {
      const showError = newEmail.length === 0;
      setShowEmailError(showError);
    }

    const newList = [...recipientList];
    newList[index] = newEmail.trim();
    setRecipientList(newList);
  }

  useEffect(() => {
    (function updateEmailListState() {
      let newEmailList = [];

      if (recipientList[0].length) { // first email input is empty
        newEmailList = recipientList.filter(email => email.length);
      }

      const locationState = location.state;
      locationState.emailList = newEmailList;
      history.replace(location.pathname, locationState);
    })();
  }, [recipientList]);

  const RecipientList = () => {
    const EmailRequired = () => <div style={{ color: Colors.redRemove, fontSize: '10px' }}>Required</div>
    const inputList = recipientList.map((email, index) => {
      const isFirstInputField = index === 0;

      return <div className='mb-1' >
        <input class='form-control' value={email}
          placeHolder='Email Address'
          onChange={e => updateEmail(e, index)} />
        {isFirstInputField && showEmailError && <EmailRequired />}
      </div>
    })

    return inputList;
  };

  const addRecipient = () => {
    trackMixpanelEventAddRecipient();
    const newList = recipientList.concat('');
    setRecipientList(newList);
  }

  const onSubmitPaymentHandler = () => {
    trackMixpanelEventSubmitPayment();
    if (paymentMethod === HEADNOTE_PAYMENT_METHODS.PLAID) {
      const plaidToken = R.path(['plaidAccountDetails', 'publicToken'], location.state)
      if (!plaidToken) {
        setIsPlaidConnectionFailed(true);
        return;
      }
    }
    submitPayment();
  }

  useEffect(() => {
    updateSavePaymentMethodState(defaultSaveMethodStatus);
  }, []);

  if (!allowGuest && !props.user) {
    return (
      <StyleContainer>
        <Row className="justify-content-md-center">
          <Col md="8">
            <Alert variant="info">
              {`Please log in to process payment.`}
              <Alert.Link href="/login">{`Click here to login`}</Alert.Link>
            </Alert>
          </Col>
        </Row>
      </StyleContainer>
    );
  }

  const FirmName = () => {
    const firmName = R.path(['firmName',], props.firm);
    return <strong>{firmName}</strong>
  }

  const TOS = () => <a href="https://www.bill4time.com/terms-of-service" target="_blank" onClick={trackMixpanelEventTOS}><strong>{`Terms & Conditions`}</strong></a>

  const onPaymentMethodChange = (method) => {
    switch (method.type) {
      case HEADNOTE_PAYMENT_METHODS.NEWCARD:
        trackMixpanelEventCreditCard();
        break;
      case HEADNOTE_PAYMENT_METHODS.PLAID:
        trackMixpanelEventPlaid();
        break;
      case HEADNOTE_PAYMENT_METHODS.ARNUMBER:
        trackMixpanelEventArNumber();
        break;
      default:
        break;
    }
    const isEnabledForPersonalUse = method.isEnabledForPersonalUse;
    setShowSavePaymentMethodCheckboxes(!isEnabledForPersonalUse); // TODO: Check later, will this param still work with new Payment Method Collections?
    props.setSelectedPaymentBankCard(method);
  }

  const PaymentMethod = ({ error = {}, }) => {
    switch (paymentMethod) {
      case HEADNOTE_PAYMENT_METHODS.NEWCARD:
        return <HeadnoteCreditCard
          vgsForm={vgsForm}
          error={error}
          vgsErrors={vgsErrors}
        />
      case HEADNOTE_PAYMENT_METHODS.PLAID:
        return <HeadnoteECheck
          isPlaidConnectionFailed={isPlaidConnectionFailed}
          error={error}
        />
      case HEADNOTE_PAYMENT_METHODS.ARNUMBER:
        return <HeadnoteECheckARNumbers
          headnoteARNumberFormRef={headnoteARNumberFormRef}
          error={error}
        />
      default:
        return <ErrorMsg error={error} />
    }
  };

  const UnallowedPaymentMethodsMap = {
    credit_card: 'Credit Card',
    debit_card: 'Debit Card',
    ach: 'ACH'
  }

  const unallowedPaymentMethodsStringArray: string[] = Object.entries(UnallowedPaymentMethodsMap)
    .filter(([key, _]) => !headnoteFirmSettings[`${key}_processing_allowed`])
    .map(([_, value]) => value);
  const singleUnallowedPmtMethod = unallowedPaymentMethodsStringArray.length === 1;

  const UnallowedPaymentMethodsString = () => {
    if (!headnoteFirmSettings) { return ''; }
    return unallowedPaymentMethodsStringArray.join('/');
  }

  return (
    <StyleContainer>
      <Row className="justify-content-md-center">
        <Col md="8">
          <h3>{`Online Payment`}</h3>
        </Col>
      </Row>

      <Row className="justify-content-md-center">
        <Col md="8">
          <StyledCard>
            {
              loading ? <Spinner animation="border" className="mr-2" role="status" /> : (
                <React.Fragment>
                  <Row className='mx-0 pb-3'>
                    <Col md='3'>
                      <h5>{`Payment Total`}</h5>
                    </Col>

                    <Col md='9'>
                      <h4>{` ${currencyFormat(props.invoicePayment && props.invoicePayment.amount, props.firm)}`}</h4>
                    </Col>
                  </Row>

                  <Row className='mx-0 pb-3'>
                    <Col md='3'>
                      <h6>{`Payment Method`}</h6>
                    </Col>
                    <Col md='9'>
                      <Dropdown
                        getOptionValue={getBankCardOptionValue}
                        defaultValue={groupedOptions[1].options[0]}
                        value={props.selectedPaymentBankCard}
                        fullWidth={true}
                        onChange={onPaymentMethodChange}
                        onMenuOpen={trackMixpanelEventPmtMethodDropdown}
                        options={groupedOptions}
                        formatGroupLabel={formatGroupLabel}
                        styles={dropdownStyles}
                        isSearchable={false}
                      />
                    </Col>
                  </Row>

                  { (headnoteFirmSettings.client_fee_enabled && (clientHeadnoteInfo && clientHeadnoteInfo.isSurchargeEnabled) && (props.selectedPaymentBankCard.type === 'card' || props.selectedPaymentBankCard.type === 'NEWCARD')) &&
                    <Row className='mx-0 pb-3'>
                      <Col md='12' style={{ display: 'inline-block', textAlignLast: 'left' }}>
                        <span style={{ display: 'inline-flex', fontWeight: 'bold' }}>Note:</span> Credit card payments are subject to a {clientHeadnoteInfo ? clientHeadnoteInfo.headnoteSurchargePercent : 0}% processing fee.  These fees do not apply to payments made with Debit cards.
                      </Col>
                    </Row>
                  }

                  <Row className='mx-0 pb-3'>
                    <Col md='3'>
                      <h6><RequiredLabel>{`Send Receipt To`}</RequiredLabel></h6>
                    </Col>
                    <Col md='9'>
                      {RecipientList()}
                      {showAddButton && <Button style={{ color: Colors.blueCurious }} variant="link"
                        onClick={addRecipient}>+ Add a Recipient
                      </Button>}
                    </Col>
                  </Row>

                  {hasUnallowedPaymentMethods &&
                    <Row className='mx-0 pb-3'>
                      <Col md='12' style={{ display: 'inline-block', textAlignLast: 'center' }}>
                        <span style={{ display: 'inline-flex', fontWeight: 'bold', fontStyle: 'italic' }}>{UnallowedPaymentMethodsString()}</span> {singleUnallowedPmtMethod ? 'is' : 'are'} not allowed for this payment. Any payment method of {singleUnallowedPmtMethod ? 'this type' : 'these types'} will be rejected.
                      </Col>
                    </Row>
                  }
                  {((hasCardTransactionLimit || hasAchTransactionLimit) && !hasNoAllowedPaymentMethods) &&
                    <Row className='mx-0 pb-3'>
                      <Col md='12' style={{ display: 'inline-block', textAlignLast: 'center' }}>
                        <span style={{ display: 'inline-flex' }}>
                          {showCardLimitMessage ? `Maximum Credit/Debit Card payment is ${currencyFormat(Math.ceil(headnoteFirmSettings.card_transaction_limit_in_cents / 100), props.firm)}.` : ''}
                          {showAchLimitMessage ? ` Maximum ACH payment is ${currencyFormat(Math.ceil(headnoteFirmSettings.ach_transaction_limit_in_cents / 100), props.firm)}.` : ''}
                        </span>
                        <span>
                          {showCardLimitMessage && showAchLimitMessage ? ' Any payments' : ' Any payment'} greater than {showCardLimitMessage && showAchLimitMessage ? 'these amounts' : 'this amount'} will be rejected.
                        </span>
                      </Col>
                    </Row>
                  }
                </React.Fragment>
              )
            }
            <StyledHr />
            <Col md='12'>
              {PaymentMethod({ error })}
              {showSavePaymentMethodCheckboxes && <>
                <div className="form-group">
                  <input type="checkbox" id="persist" name="persist" defaultChecked={defaultSaveMethodStatus}
                    onChange={e => {
                      updateSavePaymentMethodState(e.target.checked);
                    }}
                    onClick={trackMixpanelEventSaveCheckBox} /> Save payment method for my personal future use.
                </div>
                <div className='form-group'>
                  <input type='checkbox' id='persistFirm' name='persistFirm' defaultChecked={defaultSaveForFirmUseState}
                    onChange={e => {
                      updateSaveForFirmUseState(e.target.checked);
                    }}
                  /> Save payment method for {props.firm.firmName} firm to use in the future per their payment and billing terms.
                </div>
              </>}
              <div style={{ marginBottom: '20px' }}>By clicking "Submit Payment" you authorize a charge to the payment method provided for the designated amount and agree to our <TOS />.</div>

            </Col>
            <StyledHr />
            <div className="d-flex justify-content-center align-items-center">
              <div className='d-flex flex-row align-items-center'>
                {
                  props.isSubmitting && (
                    <Spinner animation="border" className="mr-2" role="status" />
                  )
                }
                <Button
                  disabled={props.isSubmitting}
                  size="lg"
                  variant="success"
                  onClick={onSubmitPaymentHandler}
                >
                  {`Submit Payment`}
                </Button>
              </div>
              <Link className="btn" to="#" onClick={goBack}>{`Cancel`}</Link>
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <span style={{ fontStyle: 'italic', fontSize: '9pt', marginTop: '5px' }} >Clicking the submit button multiple times may result in unintended duplicate transactions</span>
            </div>
          </StyledCard>
        </Col>
      </Row>
    </StyleContainer>
  );
};

export default PaymentMethodsHeadnoteV;
