// @flow
import * as R from 'ramda';

import { type BankAccountData, type CardData, type InvoicePayCart, type LawpayPayBody, NewCardData, PAYMENT_METHOD, type PaymentBody, type PaymentCard, type PendingPayment, type StripePayBody, BANK_CARD_TYPE, } from 'domain/payment';
import { type ClientPortalSettings, type Firm, type HeadnoteFirmSettings } from 'domain/firm';
import { Elements, StripeProvider, } from 'react-stripe-elements';
import { type GetUserBankAccountsSuccessPayload, type GetUserCardsSuccessPayload, type LawpayPaymentPayload, type StripePaymentPayload, type HeadnotePaymentPayload } from 'state/payment/types';
import { type InvoicePaymentRouteParams, type PaymentData, type PaymentSuccess, } from './types';
import { ClientActionCreators, HeadnoteFirmSettingsCreators, PaymentActionCreators, UserActionCreators, } from 'state/actions';
import { HeadnoteFirmSettingsRedux, PaymentRedux, UserRedux, ClientRedux } from 'state/reducers';
import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { useDispatch, useSelector, } from 'react-redux';
import { useHistory, useLocation, } from "react-router-dom";
import { HEADNOTE_PAYMENT_METHODS } from 'domain/payment';

import { API_CONFIG, APP_CONFIG, ERRORS, } from 'constants/index';
import { type FormikActions, } from 'formik';
import PaymentMethodsV from './PaymentMethodsV';
import PaymentMethodsHeadnoteV from './PaymentMethodsHeadnoteV';
import { type User, } from 'domain/user';
import _ from 'lodash';
import { loadScript, } from 'utilities/dynamicLoading';
import { type ClientHeadnoteInfo } from 'domain/client';

const trackMixPanelEventCancel = () => { window.mixpanel.track("b4tp_client_portal_cancel_payment") }

const { VGS_KEY, RECAPTCHA_KEY } = API_CONFIG;
const vgsScriptUrl = 'https://js.verygoodvault.com/vgs-collect/1/ACcHjE9RbeNfBdguytan62Bf.js';
const recaptchaScriptUrl = `https://www.google.com/recaptcha/enterprise.js?render=${RECAPTCHA_KEY}&badge=bottomleft`;
const paypalOption: PaymentData = {
  brand: 'paypal',
  id: '-1',
  last4: 'PayPal',
  type: PAYMENT_METHOD.paypal,
  label: 'Credit Card'
};

type HeadnotePaymentMethodOption = {
  label: String;
  type: String;
}
const defaultHeadnoteNewPaymentMethod: HeadnotePaymentMethodOption =
{
  label: 'Credit Card',
  type: HEADNOTE_PAYMENT_METHODS.NEWCARD,
};

type INewBankAccountInfo = {
  routingNumber: string;
  accountNumber: string;
  accountClass: HEADNOTE_BANK_ACCOUNT_CLASS;
  accountName: string;
}

// connect redux
const useConnect = () => {
  // mapState
  const firm: Firm = useSelector(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getFirm));
  const headnoteFirmSettings: HeadnoteFirmSettings = useSelector(R.pipe(HeadnoteFirmSettingsRedux.getReducerState, HeadnoteFirmSettingsRedux.selectors.getHeadnoteFirmSettings));
  const user: User = useSelector(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getUser));
  const bankAccounts: Array<BankAccountData> = useSelector(R.pipe(PaymentRedux.getReducerState, PaymentRedux.selectors.getBankAccounts));
  const creditCards: Array<CardData> = useSelector(R.pipe(PaymentRedux.getReducerState, PaymentRedux.selectors.getCreditCards));
  const clientHeadnoteInfo: ClientHeadnoteInfo = useSelector(R.pipe(ClientRedux.getReducerState, ClientRedux.selectors.getClientHeadnoteInfo));
  const token = useSelector(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getToken));
  const mapState = {
    firm,
    headnoteFirmSettings,
    user,
    bankAccounts,
    creditCards,
    token,
    clientHeadnoteInfo
  };

  // mapDispatch
  const dispatch = useDispatch();
  const mapDispatch = useMemo(() => ({
    payByStripe: (payload: StripePaymentPayload) => dispatch(PaymentActionCreators.stripePay(payload, { thunk: true, })),
    payByLawpay: (payload: LawpayPaymentPayload) => dispatch(PaymentActionCreators.lawpayPay(payload, { thunk: true, })),
    payByHeadnote: (payload: HeadnotePaymentPayload) => dispatch(PaymentActionCreators.headnotePay(payload, { thunk: true, })),
    getByToken: () => dispatch(UserActionCreators.getByToken()),
    getUserBankAccounts: () => dispatch(PaymentActionCreators.getUserBankAccounts({ thunk: true, })),
    getUserCards: () => dispatch(PaymentActionCreators.getUserCards({ thunk: true, })),
    getHeadnoteFirmSettings: (): Promise<any> => dispatch(HeadnoteFirmSettingsCreators.getHeadnoteFirmSettings({ thunk: true, })),
    getClientHeadnoteInfo: (payload: GetClientHeadnoteInfoPayload): Promise<any> => dispatch(ClientActionCreators.getClientHeadnoteInfo(payload, { thunk: true }))
  }), [dispatch,]);

  return {
    ...mapState,
    ...mapDispatch,
  };
};

/**
 * Main component
 *
 * @returns
 */
const PaymentMethodsVM = () => {
  // react redux connect
  const {
    firm,
    headnoteFirmSettings,
    payByStripe,
    payByLawpay,
    payByHeadnote,
    bankAccounts,
    creditCards,
    user,
    getByToken,
    getUserBankAccounts,
    getUserCards,
    getHeadnoteFirmSettings,
    token,
    clientHeadnoteInfo,
    getClientHeadnoteInfo
  } = useConnect();

  // router history
  const history = useHistory();
  // get amount and cart in state
  const location = useLocation();
  const portalSettings: ClientPortalSettings = R.path(['portalSettings',], firm);
  const { isLawpay, isPaypal, isStripe, isHeadnote } = portalSettings;
  const [vgsForm, setVgsForm] = useState(null);
  const [isLoaded, scriptLoaded] = useState(false);
  const [isRecaptchaLoaded, setIsRecaptchaLoaded] = useState(false);
  const [isSubmitting, setIsSubmitting,] = useState<boolean>(false);
  const [loading, setLoading,] = useState<boolean>(false);
  const [error, setError,] = useState<object>(false);
  const headnoteARNumberFormRef = useRef(null);
  const [vgsErrors, setVgsErrors] = useState({});

  const selectedOption = isHeadnote ? defaultHeadnoteNewPaymentMethod : paypalOption;
  const [selectedPaymentBankCard, setSelectedPaymentBankCard,] = useState<PaymentData | HeadnotePaymentMethodOption | undefined>(selectedOption);

  useEffect(() => {
    if (isLoaded && isHeadnote) {
      const vgsCollect = window.VGSCollect.create(VGS_KEY, function (state) { });
      setVgsForm(vgsCollect);
    }
  }, [isLoaded]);


  // memoize this so it can be used as dependency
  const locationState = useMemo<InvoicePaymentRouteParams>(() => {
    return location.state;
  }, [location.state,]);


  // only show bank account is verified
  const verifiedBankAccount = useMemo(() => {
    if (!bankAccounts || (bankAccounts && bankAccounts.length === 0)) {
      return [];
    }
    return bankAccounts.filter(item => item.status === 'verified');
  }, [bankAccounts,]);

  // add new card option to credit card options
  const bankCardOptionsWithNewCard = useMemo(() => {
    const portalSettings: ClientPortalSettings = R.path(['portalSettings',], firm);
    if (!portalSettings) { return []; }

    let options = [
      ...verifiedBankAccount,
      ...creditCards,
    ];


    // if integrated stripe || lawpay
    if (isLawpay || isStripe) {
      options = [...options, NewCardData,];
    }

    // Add paypal if integrated paypal
    if (isPaypal) {
      options = [...options, paypalOption,];
    }


    return options;
  }, [firm, verifiedBankAccount, creditCards,]);

  const headnoteSavedPaymentMethods = useMemo(() => {
    const portalSettings: ClientPortalSettings = R.path(['portalSettings',], firm);
    if (!portalSettings) { return []; }

    let options = [
      ...bankAccounts,
      ...creditCards,
    ];

    return options;
  }, [firm, bankAccounts, creditCards,]);

  useEffect(() => {
    // VGS Script
    (async () => {
      const isLoaded = await loadScript(vgsScriptUrl);
      scriptLoaded(isLoaded);
    })();

    // Google reCAPTCHA
    (async () => {
      const isRecpatchaScriptLoaded = await loadScript(recaptchaScriptUrl);
      setIsRecaptchaLoaded(isRecpatchaScriptLoaded);
    })();
  }, []);

  // Set default value, follow order Bank account > credit card > new card > paypal
  useEffect(() => {
    const portalSettings: ClientPortalSettings = R.path(['portalSettings',], firm);
    if (!portalSettings || isHeadnote) { return; }
    const hasBankAccounts = bankAccounts.length > 0;
    const hasCreditCards = creditCards.length > 0;

    if ((isLawpay || isStripe) && (!user || (!hasBankAccounts && !hasCreditCards))) {
      setSelectedPaymentBankCard(NewCardData);
    }
  }, [user, bankAccounts, creditCards, setSelectedPaymentBankCard, firm,]);

  // get user info
  useEffect(() => {
    if (!locationState) {
      history.push('/login');
    }

    // if user loggedin, they have refreshToken. if guest, they don't have refresh token
    if (token && token.refreshToken) {
      getByToken();
    }

  }, [getByToken, locationState, history, token,]);

  useEffect(() => {
    (
      async function fetchCardBank() {
        try {
          setLoading(true);

          const portalSettings: ClientPortalSettings = R.path(['portalSettings',], firm);
          if (!portalSettings) { return; }
          // if user loggedin, they have refreshToken. if guest, they don't have refresh token
          if (!token || !token.refreshToken) { return; }
          if (portalSettings.isHeadnote) {
            await Promise.all([getUserCards(), getUserBankAccounts()]);
          }
          // load bank accounts / cards if firm using stripe or lawpay
          else if (portalSettings.isStripe || portalSettings.isLawpay) {

            // if user loggedin, they have refreshToken. if guest, they don't have refresh token
            // if (token && token.refreshToken) {
            // get cards
            const res: GetUserCardsSuccessPayload = await getUserCards();
            if (res && res.cards.length !== 0) {
              //if cards exist, set card to default dropdown
              setSelectedPaymentBankCard(res.cards[0]);
            }

            // If ACH enabled, get bank account.
            if (portalSettings.achEnabled) {
              // get bank accounts
              const response: GetUserBankAccountsSuccessPayload = await getUserBankAccounts();

              //if bank accounts exist, set bank account to default dropdown
              if (response && response.bankAccounts.length !== 0) {
                const verifiedAccount = response.bankAccounts.filter(item => item.status === 'verified');
                if (verifiedAccount.length > 0) {
                  setSelectedPaymentBankCard(verifiedAccount[0]);
                }
              }
            }
          }
        } catch (err) {
          console.log('>>>>> Bank/Card error:', err);
        } finally {
          setLoading(false);
        }
      }
    )();
  }, [firm, getUserBankAccounts, getUserCards, token,]);

  useEffect(() => {
    const fetchHeadnoteFirmSettings = async () => {
      if (!isHeadnote) { return; }
      try {
        await getHeadnoteFirmSettings();
      } catch (e) {
        setError(e.message || e);
      }
    }

    const { clientId } = locationState;

    const fetchClientHeadnoteInfo = async () => {
      if (!isHeadnote) {return; }
      try {
        const getClientHeadnoteInfoPayload : GetClientHeadnoteInfoPayload = {
          clientId,
        };
        await getClientHeadnoteInfo(getClientHeadnoteInfoPayload);
      } catch (e) {
        setError(e.mesage || e);
      }
    }
    fetchHeadnoteFirmSettings();
    fetchClientHeadnoteInfo();
  }, [firm]);

  const afterSubmit = useCallback((payment: paymentSuccess) => {
    const successPath = isHeadnote ? '/b4t-payment-success' : '/payment-success';

    history.push(successPath, { paymentSuccess: payment, });
  }, [history,]);

  const vgsSubmit = (persist: boolean) => {
    return new Promise((resolve, reject) => {
      const storageType = persist ? 'persistent' : 'volatile';
      vgsForm.submit('/api/cards/' + storageType, {},
        function (status, data) {
          const result = {
            saveCard: persist,
            card_details: {
              cardholder_name: data.cardholder_name,
              card_type: vgsForm.state.card_number.cardType,
              card_number_token: data.card_number,
              card_zip_code_token: data.zip_code,
              card_cvv_token: data.cvv_code, // optional
              card_exp_date: data.exp_date,
              last4: vgsForm.state.card_number.last4, // optional
            }
          }
          resolve(result);
        },
        function (errors) {
          reject({ type: 'VGS errors', errors });
        });
    });
  }

  const getHNPaymentData = async (paymentBody: PaymentBody): INewBankAccountInfo => {
    const getHeadnoteARNumberPaymentData = async () => {
      const formRef = headnoteARNumberFormRef.current;
      let errors = formRef.errors;
      if (!formRef.dirty) {
        errors = await formRef.validateForm();
      }

      const isValid = _.isEmpty(errors);

      if (!isValid) {
        throw Error("Account input not valid");
      }

      const { routing_number, account_number, account_class } = formRef.values;

      const accountNumberlength = account_number.length;
      const accountNumberLast4 = account_number.slice(accountNumberlength - 4);
      const accountName = `${account_class.label} Account x${accountNumberLast4}`;

      const newBankAccountInfo: INewBankAccountInfo = {
        routingNumber: routing_number,
        accountNumber: account_number,
        accountClass: account_class.value,
        accountName
      };

      return newBankAccountInfo;
    }

    const { clientId, firstName, lastName, savePaymentMethod, saveForFirmUse, emailList } = locationState;
    const data: HeadnotePaymentPayload = {
      ...paymentBody,
      clientId,
      savePaymentMethod,
      saveForFirmUse,
      payerFirstName: firstName,
      payerLastName: lastName,
      frontEndErrors: { // added for logging purposes
        VGS_ERRORS: JSON.stringify(vgsErrors || {}),
        MISC_ERRORS: JSON.stringify(error || {})
      }
    };

    data.isExistingMethod = false;
    data.email = emailList;

    switch (selectedPaymentBankCard.type) {
      case HEADNOTE_PAYMENT_METHODS.SAVED_ECHECK:
        data.bank = { id: selectedPaymentBankCard.id };
        data.isExistingMethod = true;
        data.savePaymentMethod = false;
        break;
      case HEADNOTE_PAYMENT_METHODS.SAVED_CC:
        data.card = { id: selectedPaymentBankCard.id };
        data.isExistingMethod = true;
        data.savePaymentMethod = false;
        break;
      case HEADNOTE_PAYMENT_METHODS.NEWCARD:
        const { card_details } = await vgsSubmit(savePaymentMethod);
        data.card = card_details;
        break;
      case HEADNOTE_PAYMENT_METHODS.PLAID:
        const plaidAccountDetails = R.path(['plaidAccountDetails',], locationState)
        if (!plaidAccountDetails) {
          throw Error('Plaid Not Connected');
        }
        data.bank = plaidAccountDetails;
        break;
      case HEADNOTE_PAYMENT_METHODS.ARNUMBER:
        data.bank = await getHeadnoteARNumberPaymentData();
        break;
    }

    return data;
  }

  const makeHNPayment = async (paymentBody) => {
      const validateEmailForHNPayment = () => {
        const emailList = R.path(['emailList',], locationState);
        if (!emailList.length) {
          throw Error('Email Required');
        }
        emailList.forEach(email => {
          if (email.includes(' ')) {
            throw Error('Please remove all spaces from all email fields above.');
          }
        });
      }

      const getPmtMethodLabel = () => {
        switch (selectedPaymentBankCard.type) {
          case HEADNOTE_PAYMENT_METHODS.SAVED_ECHECK:
          case HEADNOTE_PAYMENT_METHODS.SAVED_CC:
            return selectedPaymentBankCard.pmtMethodLabel;
          case HEADNOTE_PAYMENT_METHODS.NEWCARD:
            return `Credit Card x${headnotePaymentData.card.last4}`;
          default: //PLAID or AR Number
            return headnotePaymentData.bank.accountName;
        }
      };

      validateEmailForHNPayment();
      const headnotePaymentData = await getHNPaymentData(paymentBody);
      const res = await payByHeadnote(headnotePaymentData);
      res.pmtMethodLabel = getPmtMethodLabel();
      return res;
  }

  const makePayment = async () => {
    const { cart, amount, projectId, clientId, retainerRequestId } = locationState;

    const userEmail = user ? user.email : "";

    // process pay request data
    const paymentCart: Array<InvoicePayCart> = cart.map((item) => ({
      invoiceId: item.id,
      retainerId: item.retainerRequestId,
      retainerBalance: item.balance,
      invoiceBalance: item.invoiceBalance,
      invoiceLabel: item.invoiceLabel,
      amountApplied: item.userPayment,
    }));

    const paymentBody: PaymentBody = {
      cart: paymentCart.filter(item => item.invoiceBalance !== APP_CONFIG.paymentCart.additionalPayment),
      amount: amount,
      email: userEmail,
      source: selectedPaymentBankCard.type === BANK_CARD_TYPE.bank ? 'ACH' : "CC",
      projectId: projectId ? Number(projectId) : undefined,
      retainerId: retainerRequestId,
      isExistingMethod: true
    };

    let res: PendingPayment | undefined;

    if (isHeadnote) {
        const captchaAction = retainerRequestId ? 'payment_request_payment' : 'invoice_payment';
        const captchaToken = await window.grecaptcha.enterprise.execute(RECAPTCHA_KEY, { action: captchaAction });
        paymentBody.captchaAction = captchaAction;
        paymentBody.captchaToken = captchaToken;
      res = await makeHNPayment(paymentBody);
    } else {
      // Check if amount is smaller that 50 cents
      if (amount < 0.5) {
        throw new Error(ERRORS.stripe_amount_at_least);
      }

      const savedId = selectedPaymentBankCard.id;
      const data: StripePaymentPayload | LawpayPaymentPayload = {
        clientId: clientId,
        payment: {
          ...paymentBody,
          methodIdOrToken: savedId,
        },
      };

      if (isStripe) {
        res = await payByStripe(data);
      }
      if (isLawpay) {
        res = await payByLawpay(data);
      }
    }

    return res;
  }

  // handle submit saved account
  const submitPayment = useCallback(async () => {
    // do nothing if no selected method, bank or card
    if (!user && !selectedPaymentBankCard) { return; }

    try {
      setIsSubmitting(true);
        
        const res: PendingPayment | undefined = await makePayment();
        // to success page
        if (res) {
          const { amount, } = locationState;

          // Response doesn't have amount so we can get amount from body
          const afterSubmitBody: PaymentSuccess = {
            ...res,
            amount: amount,
            type: selectedPaymentBankCard.type === BANK_CARD_TYPE.bank ? 'Bank Account' : 'Credit Card',
          };

          if (isHeadnote) {
            if (user) {
              await getByToken();
            }

            afterSubmitBody.type = selectedPaymentBankCard.type;
            afterSubmitBody.emailList = R.path(['emailList',], locationState);
            afterSubmitBody.firstName = R.path(['firstName',], locationState);
            afterSubmitBody.lastName = R.path(['lastName',], locationState);
            afterSubmitBody.surchargePercentage = clientHeadnoteInfo ? clientHeadnoteInfo.headnoteSurchargePercent || 0 : 0;
          }

          afterSubmit(afterSubmitBody);
        }
    } catch (e) {
      if (e && e.type && e.type == 'VGS errors') {
        setVgsErrors(e.errors);
      } else {
        setError({ msg: e.message || e, });
      }
    } finally {
      setIsSubmitting(false);
    }
  }, [user, selectedPaymentBankCard, locationState, firm, payByStripe, payByLawpay, payByHeadnote, afterSubmit, vgsForm]);

  const goBack = useCallback(() => {
    trackMixPanelEventCancel();
    history.goBack();
  }, [history,]);

  // Get publish key from firm
  const publishKey = useMemo(() => {
    const portalSettings: ClientPortalSettings = R.path(['portalSettings',], firm);
    if (portalSettings && portalSettings.stripe && portalSettings.stripe.stripePublishableKey) {
      return portalSettings.stripe.stripePublishableKey;
    }
    return API_CONFIG.stripeApiKey;
  }, [firm,]);


  if (isHeadnote) {
    return (
      <PaymentMethodsHeadnoteV
        savedPaymentMethods={headnoteSavedPaymentMethods}
        error={error}
        firm={firm}
        goBack={goBack}
        invoicePayment={locationState}
        isSubmitting={isSubmitting}
        loading={loading}
        selectedPaymentBankCard={selectedPaymentBankCard}
        setSelectedPaymentBankCard={setSelectedPaymentBankCard}
        submitPayment={submitPayment}
        user={user}
        vgsForm={vgsForm}
        headnoteARNumberFormRef={headnoteARNumberFormRef}
        vgsErrors={vgsErrors}
        headnoteFirmSettings={headnoteFirmSettings}
        clientHeadnoteInfo={clientHeadnoteInfo}
      />
    );
  }
  return (
    <StripeProvider apiKey={publishKey}>
      <Elements>
        <PaymentMethodsV
          afterSubmit={afterSubmit}
          bankCards={bankCardOptionsWithNewCard}
          error={error}
          firm={firm}
          goBack={goBack}
          invoicePayment={locationState}
          isSubmitting={isSubmitting}
          loading={loading}
          selectedPaymentBankCard={selectedPaymentBankCard}
          setSelectedPaymentBankCard={setSelectedPaymentBankCard}
          submitPayment={submitPayment}
          user={user}
        />
      </Elements>
    </StripeProvider>
  );
};

export default PaymentMethodsVM;
