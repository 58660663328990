// @flow
import * as R from 'ramda';

import Button from 'react-bootstrap/Button';
import { type ClientPortalSettings, } from 'domain/firm';
import React from 'react';
import { UserRedux, } from 'state/reducers';
import { useSelector, } from 'react-redux';

type Props = {
  onSubmit: Function,
  text?: string
}

export default ({ onSubmit, text } : Props) => {
  const portalSettings: ClientPortalSettings = useSelector(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getPortalSettings));

  if(portalSettings && portalSettings.isCustom) {
    return (
      <a
        className="btn btn-success btn-lg" 
        href={portalSettings.custom ? portalSettings.custom.url : ''} 
        target="blank"
      >
        { text || `Pay Now`}
      </a>
    );
  }

  return (
    <Button
      size="lg"
      variant="success"
      onClick={onSubmit}
    >
      {text || `PAY NOW`}
    </Button>
  );
};
