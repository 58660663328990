/* @flow */
import * as R from 'ramda';

import { Field, Formik, type FormikActions, type FormikProps, useFormikContext, } from 'formik';
import { FormBoolean, FormSelect, FormInput } from 'view/components/index';
import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';

import { Card, Col, OverlayTrigger, Tooltip, Spinner } from 'react-bootstrap';
import { type ClientPortalSettings, PAYMENT_OPTIONS, type Firm, type HeadnoteFirmSettings } from 'domain/firm';
import { type FirmUpdateRequestBody, } from 'infra/firm/FirmRepository';
import { INVOICE_EMAIL_LINK_EXPIRATION, } from 'domain/invoice';
import Styled from 'styled-components';
import { type UpdateFirmSettingsPayload, } from 'state/user/types';
import { HeadnoteFirmSettingsCreators, UserActionCreators, PaymentActionCreators, } from 'state/actions';
import { HeadnoteFirmSettingsRedux, UserRedux, } from 'state/reducers';
import debounce from 'just-debounce-it';
import { getStripeAuthUrl, } from 'domain/payment';
import { openPopupWindow, } from 'utilities/generalUtils';
import { useDispatch, useSelector, } from 'react-redux';
import { MESSAGES, ERRORS, } from 'constants/index';
import ModalDisconnect from '../ModalDisconnect';
import { FontAwesomeIcon, } from '@fortawesome/react-fontawesome';
import { faQuestionCircle, } from '@fortawesome/free-solid-svg-icons';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import ClearIcon from '@material-ui/icons/Clear';
import { Colors } from 'assets';

const StyledFormSelect = Styled(FormSelect)`
  .invoice-email-link {
    width: 89.58px !important;
  };
`;

const StyledFieldHeadnoteSurchargePercent = Styled(FormInput)`
  input[name='headnoteSurchargePercent'].is-invalid,
  input[name='headnoteSurchargePercent'].is-invalid:focus {
    border-color: #E95656;
  }

  input[name='headnoteSurchargePercent'] + div {
    color: #981717;
  }
`;

const SurchargeEnabledStyles = {
  float: 'right',
  padding: '2px 4px',
  width: 'fit-content',
  borderRadius: '4px',
  fontWeight: 600,
  color: '#1D534A', // FOREST_500
  background: '#DCF8F3' // FOREST_100
}

type Props = {
  settings: ClientPortalSettings,
  updateFirmSettings: Function,
  setErrors: Function,
};

type FirmSettingsFormValues = FirmUpdateRequestBody & {
  show_accounting: boolean,
};

// Invoice email link expiration dropdown options arrat from INVOICE_EMAIL_LINK_EXPIRATION value map
const INVOICE_EMAIL_LINK_EXPIRATION_OPTIONS = Object.values(INVOICE_EMAIL_LINK_EXPIRATION);

const getInvoiceTTLDropDownLabel = (c: number): string => {
  return `${c} days`;
};
const getInvoiceTTLOptionValue = (c: number): number => c;
const getInvoiceTTLOptionLabel = (c: number): string => {
  return `${c} days`;
};

/**
 * Formik Autosave component
 *
 * @param {{ debounceMS: number, }} { debounceMS, }
 * @returns
 */
const AutoSave = ({ settings, debounceMS, dirty, }: { settings?: Object, debounceMS: number, dirty: boolean, }) => {
  const { authToken, } = useSelector(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getToken));

  const formik = useFormikContext();

  const debouncedSubmit = useCallback(
    debounce(
      async () => {
        await formik.submitForm();
      },
      debounceMS
    ),
    [ debounceMS, formik.submitForm, ]
  );

  // debounce submit whenever values change
  useEffect(() => {
    // don't submit before adminToken available or form not dirty
    if (!authToken || !dirty) {return;}

    debouncedSubmit();
  }, [ debouncedSubmit, formik.values, authToken, ]);

  return <></>
};

const useConnect = () => {
  // mapState
  const headnoteFirmSettings: HeadnoteFirmSettings = useSelector(R.pipe(HeadnoteFirmSettingsRedux.getReducerState, HeadnoteFirmSettingsRedux.selectors.getHeadnoteFirmSettings));
  const mapState = {
    headnoteFirmSettings
  };

  // mapDispatch
  const dispatch = useDispatch();
  const mapDispatch = useMemo(() => ({
    getFirm: () => dispatch(UserActionCreators.getFirm()),
    disconnectCustomPayment: () => dispatch(PaymentActionCreators.disconnectCustomPayment(undefined, { thunk: true, })),
    disconnectLawpay: () => dispatch(PaymentActionCreators.disconnectLawpay(undefined, { thunk: true, })),
    getHeadnoteFirmSettings: (): Promise<any> => dispatch(HeadnoteFirmSettingsCreators.getHeadnoteFirmSettings({ thunk: true, })),
  }), [ dispatch, ]);

  return {
    ...mapState,
    ...mapDispatch,
  };
};

const SettingsGroup = ({ settings = {}, updateFirmSettings, setErrors, }: Props) => {
  const { headnoteFirmSettings, getFirm, disconnectCustomPayment, disconnectLawpay, getHeadnoteFirmSettings } = useConnect();

  const initialValues = useMemo<FirmSettingsFormValues>(() => {
    return {
      achEnabled: settings.achEnabled,
      allowGuests: settings.allowGuests,
      disableBalances: settings.disableBalances,
      hideAccounting: settings.hideAccounting,
      sendInvoiceEmails: settings.sendInvoiceEmails,
      usePaymentStatus: settings.usePaymentStatus,
      show_accounting: !settings.hideAccounting,
      invoiceTtl: settings.invoiceTtl,
      disableOverpayment: settings.disableOverpayment,
      headnoteSurchargePercent: `${settings.headnoteSurchargePercent}%`
    };
  }, [ settings, ]);

  const isSurchargeRequired = useMemo(() => {
    return settings?.isHeadnote && headnoteFirmSettings?.client_fee_enabled;
  }, [settings, headnoteFirmSettings]);

  // get strip auth url
  const stripeAuthUrl = useMemo(() => {
    return getStripeAuthUrl();
  }, []);

  // Open window popup
  const onOpenWindowPopup = useCallback(() => {
    const popup = openPopupWindow(stripeAuthUrl, 'stripe-connect');

    if (!popup) return;
    // polling for popup window close
    const timer = setInterval(() => { 
      if(popup.closed) {
        clearInterval(timer);
        // update firm data
        getFirm()
      }
    }, 1000);

  }, [ stripeAuthUrl, getFirm, ]);

  // Handle connect stripe
  const [ showConfirmModal, setShowConfirmModal, ] = useState(false);
  // selected payment portal to stop
  const [ portal, setPortal, ] = useState(null);
  // Show description
  const [ description, setDescription, ] = useState(MESSAGES.setting.remove);
  const [ loading, setLoading, ] = useState(false);

  useEffect(() => {
    const fetchHeadnoteFirmSettings = async () => {
      if (!settings?.isHeadnote) { return; }
      try {
        await getHeadnoteFirmSettings();
      } catch (e) {
        setErrors({
          auth: e.message || e,
        });
      }
    }
    fetchHeadnoteFirmSettings();
  }, [settings]);

  const onModalCancel = useCallback<() => void>(() => {
    setShowConfirmModal(false);
  }, []);
  
  // stop payment callback to pass to payment tab
  const onStopPaymentClick = useCallback<( name: string, message: string, ) => void>(
    ( name, message = MESSAGES.setting.remove, ) => {
      setPortal(name);
      setShowConfirmModal(true);
      if (message) {
        setDescription(message);
      }
    }, 
  []);

  // handle disconnect
  const onDisconnectCP = useCallback(async () => {
    try {
      setLoading(true);
      onModalCancel();
      switch(portal) {
        
        case PAYMENT_OPTIONS.custom.key:
          await disconnectCustomPayment();
          break;

        case PAYMENT_OPTIONS.lawPay.key:
          await disconnectLawpay();
          break;
        
        default: 
          break;
      }
      
      // Run reconnect after disconnect current payment
      onOpenWindowPopup();    
    } catch (e) {
      setErrors({
        auth: ERRORS.disconnect_custom_payment,
      });
    } finally {
      setLoading(false);
    }
  }, [ onModalCancel, portal, onOpenWindowPopup, disconnectCustomPayment, disconnectLawpay, setErrors, ]
  );

  const onConnectStripe = useCallback(() => {
    // Check if lawpay or custom payment integrated
    if(settings.isLawpay) {
      const message = MESSAGES.setting.removeCurrentPayment(PAYMENT_OPTIONS.lawPay.label);
      onStopPaymentClick(PAYMENT_OPTIONS.lawPay.key, message, PAYMENT_OPTIONS.stripe.key);
      return;
    }
    if(settings.isCustom) {
      const message = MESSAGES.setting.removeCurrentPayment(PAYMENT_OPTIONS.custom.label);
      onStopPaymentClick(PAYMENT_OPTIONS.custom.key, message, PAYMENT_OPTIONS.stripe.key);
      return;
    }
    onOpenWindowPopup();
  }, [ onStopPaymentClick, settings, ])

  /**
   * on update firm settings
   * 
   * @param {FirmUpdateRequestBody} values - info values from form
   * @param {FormikActions} actions - formik bag actions
   */
  const onSubmit = useCallback(async (values: FirmSettingsFormValues, actions: FormikActions) => {
    try {
      actions.setSubmitting(true);

      // Only save surcharge data if enabled
      values.headnoteSurchargePercent = isSurchargeRequired ? values.headnoteSurchargePercent?.replace('%', '') : undefined;

      // form use show_accounting instead of hideAccounting
      // so we handle that and remove it from submit data
      const payload: UpdateFirmSettingsPayload = {
        data: {
          ...R.omit(['show_accounting'], values),
          hideAccounting: !values.show_accounting,
        }
      };
      await updateFirmSettings(payload);
    } catch (e) {
      // handle error
      setErrors({
        auth: e.message || e,
      });
    } finally {
      actions.setSubmitting(false);
    }
  }, [ setErrors, updateFirmSettings, ]);

const validateSurchargePercentage = (value) => {
  let error;
  if (!value) {
    error = 'Required';
  } else {
    let stringValue = value.toString();

    // Percentage symbol should only be at the end
    if (stringValue.endsWith('%')) {
      stringValue = stringValue.slice(0, -1);
    }

    const parsedValue = parseFloat(stringValue.replace('%', ''));
    if (isNaN(stringValue) || isNaN(parsedValue)) {
      error = 'Invalid percentage value';
    } else if (parsedValue < 0) {
      error = 'Can\'t be less than 0%';
    } else if (parsedValue > 3) {
      error = 'Can\'t be more than 3%';
    } else if (!/^\d*\.?\d{0,2}$/.test(parsedValue.toString())) {
      error = 'Can\'t have more than 2 decimal places';
    }
  }
  return error;
};

  return (
    <>
      <Card as={Col} border="light" className="px-0">
        <Card.Header className="font-weight-bold px-0">
          {`Settings`}
        </Card.Header>

        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={onSubmit}
        >
          {
            ({ errors, handleSubmit, dirty, }: FormikProps<FirmSettingsFormValues>) => (
              <form className="pt-3" onSubmit={handleSubmit}>
                <AutoSave debounceMS={300} dirty={dirty} settings={settings} />

                <Field
                  required
                  className={`mb-4 align-items-center`}
                  component={FormBoolean}
                  label={(
                    <Card.Text>
                      {`Send automatic invoice notification emails?`}
                      <OverlayTrigger
                        overlay={
                          <Tooltip>{`Finalizing invoices will automatically send alerts to your clients as long as they have accepted your invitation to the Client Portal.`}</Tooltip>
                        }
                      >
                        <FontAwesomeIcon className="ml-2" icon={faQuestionCircle} />
                      </OverlayTrigger>
                    </Card.Text>
                  )}
                  name={'sendInvoiceEmails'}
                />

                <Field
                  isRow
                  required
                  className={`mb-4 align-items-center`}
                  component={StyledFormSelect}
                  controlWrapperClass={`d-flex flex-row justify-content-end align-item-center`}
                  getDropDownLabel={getInvoiceTTLDropDownLabel}
                  getOptionLabel={getInvoiceTTLOptionLabel}
                  getOptionValue={getInvoiceTTLOptionValue}
                  inputSize={5}
                  isSearchable={false}
                  label={(
                    <Card.Text className="w-100">
                      {`Invoice email link expiration`}
                      <OverlayTrigger
                        overlay={
                          <Tooltip>{`Invoice links will expire after selected number of days.`}</Tooltip>
                        }
                      >
                        <FontAwesomeIcon className="ml-2" icon={faQuestionCircle} />
                      </OverlayTrigger>
                    </Card.Text>
                  )}
                  labelSize={7}
                  name={`invoiceTtl`}
                  options={INVOICE_EMAIL_LINK_EXPIRATION_OPTIONS}
                  placeholder={`Link expiration time`}
                  selectClassName="invoice-email-link"
                />

                <Field
                  required
                  className={`mb-4 align-items-center`}
                  component={FormBoolean}
                  label={(
                    <Card.Text>
                      {`Allow clients to view their account history?`}
                      <OverlayTrigger
                        overlay={
                          <Tooltip>{`Clients will be able to view their billing and payment history when logged into the Client Portal. Clients viewing their invoices with Guest Access will not be able to review their history.`}</Tooltip>
                        }
                      >
                        <FontAwesomeIcon className="ml-2" icon={faQuestionCircle} />
                      </OverlayTrigger>
                    </Card.Text>
                  )}
                  name={'show_accounting'}
                />

                <Field
                  required
                  className={`mb-4 align-items-center`}
                  component={FormBoolean}
                  label={(
                    <Card.Text>
                      {`Display balances?`}
                      <OverlayTrigger
                        overlay={
                          <Tooltip>{`Allows clients to see current account balance, as well as the remaining balance left to be paid on the invoice. When 'No' is selected, clients will only see the invoice total and amount remaining for the invoice.`}</Tooltip>
                        }
                      >
                        <FontAwesomeIcon className="ml-2" icon={faQuestionCircle} />
                      </OverlayTrigger>
                    </Card.Text>
                  )}
                  name={'disableBalances'}
                />

                <Field
                  required
                  className={`mb-4 align-items-center`}
                  component={FormBoolean}
                  label={(
                    <Card.Text>
                      {`Allow guest access?`}
                      <OverlayTrigger
                        overlay={
                          <Tooltip>{`Guest access allows clients to view and pay invoices without having to log into the Client Portal.`}</Tooltip>
                        }
                      >
                        <FontAwesomeIcon className="ml-2" icon={faQuestionCircle} />
                      </OverlayTrigger>
                    </Card.Text>
                  )}
                  name={'allowGuests'}
                />
                {/* This is future functionality... {settings.isHeadnote && <Field
                  required
                  className={`mb-4 align-items-center`}
                  component={FormBoolean}
                  label={(
                    <Card.Text>
                      {`Allow eCheck Payments?`}
                      <OverlayTrigger
                        overlay={
                          <Tooltip>{`Simplify end client payments by allowing them to pay via eCheck by securely signing in to their bank account.`}</Tooltip>
                        }
                      >
                        <FontAwesomeIcon className="ml-2" icon={faQuestionCircle} />
                      </OverlayTrigger>
                    </Card.Text>
                  )}
                  name={'achEnabled'}
                />} */}
                {settings.isStripe && <Field
                  required
                  className={`mb-4 align-items-center`}
                  component={FormBoolean}
                  label={(
                    <Card.Text>
                      {`Accept ACH Payments?`}
                      <OverlayTrigger
                        overlay={
                          <Tooltip>{`Simplify end client payments by allowing them to pay via ACH.`}</Tooltip>
                        }
                      >
                        <FontAwesomeIcon className="ml-2" icon={faQuestionCircle} />
                      </OverlayTrigger>
                    </Card.Text>
                  )}
                  name={'achEnabled'}
                />}
                <Field
                  required
                  className={`mb-4 align-items-center`}
                  component={FormBoolean}
                  label={(
                    <Card.Text>
                      {`Prevent client portal payments from exceeding remaining account balance?`}
                      <OverlayTrigger
                        overlay={
                          <Tooltip>{`When set to Yes, clients will not be able to pay more than their remaining Account balance via the Client Portal. Does NOT apply to Payment Requests where a Trust bank account has been selected`}</Tooltip>
                        }
                      >
                        <FontAwesomeIcon className="ml-2" icon={faQuestionCircle} />
                      </OverlayTrigger>
                    </Card.Text>
                  )}
                  name={'disableOverpayment'}
                />
              </form>
            )
          }
        </Formik>

      {isSurchargeRequired && <>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={onSubmit}
        >
          {
            ({ errors, handleSubmit, handleChange, handleBlur, isValidating, isSubmitting }: FormikProps<FirmSettingsFormValues>) => {
              const customHandleChange = (e) => {
                handleChange(e);
              }
              return (
                <form onSubmit={handleSubmit}>
                  <div className={`mx-0 mb-4 align-items-center row`}>
                    <label className={'pl-0 pb-1 form-label col-form-label col-md-9 col-sm-12'} style={{ paddingLeft: 0 }}>
                      <Card.Text>
                        Credit card payment surcharges enabled?
                        <OverlayTrigger
                          overlay={
                            <Tooltip>{`Clients will be subject to surcharges when making online payment with a credit card.`}</Tooltip>
                          }
                        >
                          <FontAwesomeIcon className="ml-2" icon={faQuestionCircle} />
                        </OverlayTrigger>
                      </Card.Text>
                    </label>

                    <div className={'pr-0 col-md-3 col-sm-12'}>
                      <div style={SurchargeEnabledStyles}>
                        Enabled
                      </div>
                    </div>
                  </div>

                  <div>
                    <Field
                      isRow
                      required
                      disabled={isSubmitting} // CHECK
                      type={'string'}
                      inputSize={4}
                      placeholder={'0.00%'}
                      component={StyledFieldHeadnoteSurchargePercent}
                      style={{ display: 'flex', textAlign: 'right' }}
                      className={`mx-0 mb-4 align-items-center row`}
                      controlWrapperClass={`pr-0 col-md-3 col-sm-12`}
                      labelClassName={`pl-0 pb-1 form-label col-form-label col-md-9 col-sm-12 d-flex justify-content-between align-items-center`}
                      label={(
                        <>
                          <Card.Text style={{ float: 'left', margin: 0 }}>
                            <>{`Credit card payment surcharge percent (%)`}</>
                            <OverlayTrigger
                              overlay={
                                <Tooltip>{`Set your credit card surcharge percent between 0.00% and 3.00%.`}</Tooltip>
                              }
                            >
                              <FontAwesomeIcon className="ml-2" icon={faQuestionCircle} />
                            </OverlayTrigger>
                          </Card.Text>
                          {!isValidating && isSubmitting &&
                            <Spinner
                              animation='border'
                              className='mr-2'
                              role='status'
                              style={{ width: '15px', height: '15px', float: 'right' }}
                            />
                          }
                          
                          {errors.headnoteSurchargePercent ?
                            <ClearIcon style={{ color: '#ee6723' }} /> :
                            <OverlayTrigger
                              overlay={
                                <Tooltip>{`Saved`}</Tooltip>
                              }
                            >
                              <FontAwesomeIcon icon={faCheck} style={{ color: Colors.greenCheck }} />
                            </OverlayTrigger>
                          }
                        </>
                      )}
                      name={'headnoteSurchargePercent'}
                      validate={validateSurchargePercentage}
                      onBlur={(e) => {
                        handleBlur(e);
                        // Submit form manually when leaving the surcharge field
                        handleSubmit();
                      }}
                      onChange={customHandleChange}
                    />
                  </div>

                </form>
              )
            }
          }

        </Formik>
      </>}
    </Card>
    <ModalDisconnect
      description={description}
      show={showConfirmModal}
      onHide={onModalCancel}
      onSubmit={onDisconnectCP}
    />
  </>
);
};

export default SettingsGroup;
