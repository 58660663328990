// @flow
import * as R from 'ramda';

import { UserRedux, PaymentRedux, } from 'state/reducers';
import { type Invoice, isInvoiceCanPay, } from 'domain/invoice';
import { currencyFormat, } from 'utilities/stringUtils';
import { type GetInvoicePaymentsPayload, type InvoicePaymentMap, } from 'state/payment/types';

import { PaymentActionCreators, } from 'state/actions';
import { Card, Button, }  from 'react-bootstrap';
import { Colors, } from 'assets/index';
import { type Firm, } from 'domain/firm';
import { PayNow, } from 'view/components';
import React, { useMemo, useEffect, } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch, } from 'react-redux';
import HistoryTable from './HistoryTable';
import _ from 'lodash';

const trackMixpanelEvent = () => { window.mixpanel.track("b4tp_client_portal_invoice_pay_now") }

type Props = {
  onPayNow: Function,
  currencyOption: ?Object,
  invoice?: Invoice,
}

const StyledButton = styled.div`
  a, button {
    width: 100%;
  }
`;

const StyledPaidButton = styled(Button)`
  color: ${Colors.whiteBlur};
  border-width: 2px;
  background-color: ${Colors.greenLight};
  border-color: ${Colors.greenLight};
  font-weight: 700;
  opacity: 1;
  svg {
    color: ${Colors.greenCredit};
    font-size: 15px;
  }
  &:hover, &:focus, &:visited, &:active {
    background-color: ${Colors.greenLight} !important;
    color: ${Colors.whiteBlur} !important;
    cursor: auto !important;
    box-shadow: 0px 0px 0px !important;
    border-color: ${Colors.greenLight} !important;
  }
`;

// connect redux
const useConnect = () => {
  // mapState
  const firm: Firm = useSelector(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getFirm));
  const paymentsMap: InvoicePaymentMap = useSelector(R.pipe(PaymentRedux.getReducerState, PaymentRedux.selectors.getInvoicePayments));
  const headnoteApplication = useSelector(R.pipe(PaymentRedux.getReducerState, PaymentRedux.selectors.getHeadnoteApplication));

  const mapState = {
    firm,
    paymentsMap,
    headnoteApplication
  };

  // mapDispatch
  const dispatch = useDispatch();

  const mapDispatch = useMemo(() => ({
    fetchPayments: (payload: GetInvoicePaymentsPayload) => dispatch(PaymentActionCreators.getInvoicePayments(payload)),
  }), [ dispatch, ]);

  return {
    ...mapState,
    ...mapDispatch,
  };
};

/**
 * Main Component
 *
 * @param {Props} { onPayNow, currencyOption, }
 * @returns
 */
const PaymentTotal = ({ invoice = {}, onPayNow, currencyOption, }: Props)=> {
  const { firm, paymentsMap, fetchPayments, headnoteApplication } = useConnect();
  const invoiceCanPay = isInvoiceCanPay(invoice);
  const showBalances = Boolean(R.path([ 'portalSettings', 'disableBalances', ], firm));

  // Fetch Payments
  const listPayment = useMemo(() => {
    if (!_.isMap(paymentsMap)) {
      return [];
    }
    return Array.from(paymentsMap.values());
  }, [ paymentsMap, ]);

  // fetch payment by invoice id
  useEffect(() => {
    if (invoice && invoice.id) {
      const payload: GetInvoicePaymentsPayload = {
        invoiceId: invoice.id,
      };

      fetchPayments(payload);
    }
  }, [ fetchPayments, firm, invoice, ]);

  /**
   * Check if firm has integration payment
   */
  const noPayment = useMemo(() => {
    const portalSettings = R.path([ 'portalSettings', ], firm);
    if (!portalSettings) {
      return false;
    }

    const { isLawpay, isPaypal, isStripe, isCustom, isHeadnote } = portalSettings;
    const isHeadnoteActive = isHeadnote && headnoteApplication.status === 'VERIFIED';
    if (!isLawpay && !isPaypal && !isStripe && !isCustom && !isHeadnoteActive) {
      return true;
    }
    return false;
  }, [ firm, headnoteApplication]);

  return (
    <Card>
      <Card.Title className="text-center pt-4 px-4">
        {
          invoiceCanPay ? (
            <StyledButton onClick={trackMixpanelEvent}>
              {!noPayment && <PayNow onSubmit={onPayNow} text={'PAY INVOICE'}/>}
            </StyledButton>
          ) : (
            <StyledPaidButton block className="text-black" size="lg" variant="outline-success">
              {`PAID`}
            </StyledPaidButton>
          )
        }
      </Card.Title>
      <Card.Body>
        <div className="text-left mb-2 border-bottom pb-2">
          <LabelTotal>{`Invoice Total: `}</LabelTotal>
          <TotalValue>{currencyFormat(invoice.invoiceTotal, currencyOption)}</TotalValue>
        </div>
        {
          showBalances && (
            <div className="text-left mb-4">
              <LabelTotal>{`Invoice Amount Remaining: `}</LabelTotal>
              <TotalValue>{currencyFormat(invoice.invoiceBalance, currencyOption)}</TotalValue>
            </div>
          )
        }
        {
          listPayment && listPayment.length > 0 &&
          <div className="text-left mb-4">
            <LabelTotal className="mb-3">{`Payments: `}</LabelTotal>
            <HistoryTable 
              data={listPayment}
            />
          </div>
        }
      </Card.Body>
    </Card>
  );
};

const LabelTotal = styled.div`
  color: ${Colors.black};
  font-size: 1.4rem;
`;

const TotalValue = styled.div`
  font-weight: 700;
  font-size: 1.5rem;
`;

export default PaymentTotal;
