/* @flow */
import * as R from 'ramda';

import { Colors, Images, } from 'assets/index';
import { Link, NavLink, useLocation, useParams, } from 'react-router-dom';
import React, { useMemo, useCallback, } from 'react';
import { useDispatch, useSelector, } from 'react-redux';

import Container from "react-bootstrap/Container";
import { type Firm, } from 'domain/firm';
import { Image, Nav, NavDropdown, Navbar, NavbarBrand, } from 'react-bootstrap';
import NavbarCollapse from 'react-bootstrap/NavbarCollapse';
import NavbarToggle from 'react-bootstrap/NavbarToggle';
import { type User, type UserToken, } from 'domain/user';
import { type AccountBalance, } from 'domain/client';
import { type Invoice, } from 'domain/invoice';
import { UserActionCreators, } from 'state/actions';
import { UserRedux, ClientRedux, ProjectRedux, InvoiceRedux, PaymentRedux } from 'state/reducers';
import styled from 'styled-components';
import { useHistory, } from "react-router-dom";
import { PayNow, } from 'view/components';
import { getDisplayAccountBalanceText, getAdjacentUrlParams, getDisplayAccountText, } from 'utilities/stringUtils';
import { type InvoiceMap, } from 'state/invoice/types';

const trackMixpanelEventAccountHistory = () => { window.mixpanel.track("b4tp_client_portal_account_history"); }
const trackMixpanelEventHome = () => { window.mixpanel.track("b4tp_client_portal_home"); }
const trackMixpanelEventFirmLogo = () => { window.mixpanel.track("b4tp_client_portal_firm_logo"); }
const trackMixpanelEventUserName = () => { window.mixpanel.track("b4tp_client_portal_user_name"); }
const trackMixpanelEventMyAccount = () => { window.mixpanel.track("b4tp_client_portal_my_account"); }
const trackMixpanelEventLogout = () => { window.mixpanel.track("b4tp_client_portal_log_out"); }
const trackMixpanelEventPayNow = () => { window.mixpanel.track("b4tp_client_portal_header_pay_now"); }

type Props = {
};

const StyledContainer = styled.div`
  background-color: ${Colors.darkBlue};
  border-bottom: 1px solid ${Colors.blueTropaz};
`;

const StyledNavbar = styled(Navbar)`
  .portal-client-logo {
    width: 132px;
    height: 32px;
  },
  
  .style-link {
    font-weight: 500;
    color: ${Colors.lightGrey} !important;

    :hover {
      color: white !important;
      text-decoration: none;
    }
  }

  .dropdown-toggle {
    font-weight: 500;
    color: ${Colors.lightGrey} !important;

    :hover {
      color: white !important;
    }
  }

  .dropdown-menu {

    .dropdown-item {
      :hover {
        text-decoration: underline;
      }
    }
  }
`;

const StyledBalance = styled.h2`
  font-family: 'Montserrat-Bold' !important;
  color: #fff;
  text-shadow: 0px 0px 10px #000;
`;

const StyledBalanceText = styled.div`
  font-family: 'Montserrat-Bold' !important;
  color: #fff;
  text-shadow: 0px 0px 10px #000;
`;

const useConnect = () => {
  // map state to props
  const user: User = useSelector(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getUser));
  const firm: Firm = useSelector(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getFirm));
  const accountBalance: AccountBalance = useSelector(R.pipe(ClientRedux.getReducerState, ClientRedux.selectors.getAccountBalance));
  const selectedClient = useSelector(R.pipe(ClientRedux.getReducerState, ClientRedux.selectors.getSelectedClient));
  const selectedProject = useSelector(R.pipe(ProjectRedux.getReducerState, ProjectRedux.selectors.getSelectedProjects));
  const invoicesMap: InvoiceMap = useSelector(R.pipe(InvoiceRedux.getReducerState, InvoiceRedux.selectors.getInvoices));
  const tokens: UserToken = useSelector(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getToken));
  const headnoteApplication = useSelector(R.pipe(PaymentRedux.getReducerState, PaymentRedux.selectors.getHeadnoteApplication));

  // map dispatch to props
  const dispatch = useDispatch();
  const mapDispatch = useMemo(() => ({
    onLogout: () => dispatch(UserActionCreators.logout()),
  }), [ dispatch, ]);

  return {
    user,
    firm,
    accountBalance,
    selectedClient,
    selectedProject,
    invoicesMap,
    tokens,
    headnoteApplication,
    ...mapDispatch,
  };
};

const CustomNavbarMenu = (props: Props) => {
  const { user, firm, onLogout, accountBalance, selectedClient, selectedProject, invoicesMap, tokens, headnoteApplication } = useConnect();
  const location = useLocation();
  const isHideAccounting = R.path([ 'portalSettings', 'hideAccounting', ], firm);
  const disableBalances = R.path([ 'portalSettings', 'disableBalances', ], firm);
  const history = useHistory();
  const balance = R.path([ 'balance', ], accountBalance);

  // route params and state
  const { id: invoiceId, clientId, projectId, } = useParams();

  /**
   * Check if firm has integration payment
   */
  const noPayment = useMemo(() => {
    if (firm && firm.portalSettings) {
      const { isLawpay, isPaypal, isStripe, isCustom, isHeadnote } = firm.portalSettings;
      const isHeadnoteActive = isHeadnote && headnoteApplication.status === 'VERIFIED';

      if (!isLawpay && !isPaypal && !isStripe && !isCustom && !isHeadnoteActive) {
        return true;
      }
    }
    return false;
  }, [ firm, headnoteApplication]);

  const onClickLogout = useCallback(() => {
    trackMixpanelEventLogout();
    onLogout();
    history.push('/login');
  }, [ history, onLogout, ]);

  // transform invoice, payment map to value array
  const listInvoice = useMemo(() => {
    return Array.from(invoicesMap.values());
  }, [ invoicesMap, ]);

  // check if has unpaid invoice base on paid status
  const hasUnpaidInvoice = useMemo(() => {
    if (!listInvoice) {return false;}

    return listInvoice.some(i => {
      return i.isPaid === false && i.invoiceBalance > 0;
    });

  }, [ listInvoice, ]);

  /**
   * handle pay now button press
   * nav to invoice payment screen
   */
  const onPayNow = useCallback(() => {
    trackMixpanelEventPayNow();
    const clientId = selectedClient ? selectedClient.id : '';
    const projectId = selectedProject ? selectedProject.id : '';

    // do nothing if no client selected
    if (!clientId) {return;}

    // nave to invoice payment page with selected client and project id
    const url = `/invoice-payment/${clientId}${getAdjacentUrlParams(projectId)}`;

    history.push(url);
  }, [ selectedClient, selectedProject, history, ]);

  /**
   * handle pay now button press
   * nav to invoice payment screen
   */
  const onPayNowNonLoggedIn = useCallback(() => {
    // do nothing if no invoiceId
    if (!invoiceId) {return;}

    // nav to invoice payment page with selected invoiceId
    // TODO: invoice payment with invoice id instead of client and project id ?
    let url = `/invoice-payment-single/${invoiceId}${getAdjacentUrlParams(clientId)}${getAdjacentUrlParams(projectId)}`;

    history.push(url);
  }, [ invoiceId, history, clientId, projectId, ]);

  return (
    <StyledContainer className='py-1 header-navbar'>
      <Container>
        <StyledNavbar className="pt-0 pb-0" expand='md' variant="dark">

          <NavbarBrand as={Link} to='/'>
            <Image alt='portal-client-logo' className='portal-client-logo' onClick={trackMixpanelEventFirmLogo} src={Images.common.portalClientLogo} />
          </NavbarBrand>

          <NavbarToggle aria-controls='header-navbar__content' />
          <NavbarCollapse id='header-navbar__content'>
            <Nav className='mr-auto'>
              {
                user && (
                  <React.Fragment>
                    <NavLink activeClassName='selected-link' className='nav-link style-link px-md-5' onClick={trackMixpanelEventHome} to={'/'}>{`Home`}</NavLink>
                    {
                      !isHideAccounting && (
                        <NavLink activeClassName='selected-link' className='nav-link style-link pl-0' onClick={trackMixpanelEventAccountHistory} to={'/accounting'}>{`Account History`}</NavLink>
                      )
                    }
                  </React.Fragment>
                )
              }
            </Nav>
            {
              user
                ? (
                  <Nav className="align-items-md-center">
                    {
                      disableBalances && (
                        <Nav.Item className="d-flex flex-row align-items-center mr-3">
                          <div className="text-md-center mr-2">
                            <StyledBalance className="m-0" max={30} mode="single">
                              {getDisplayAccountBalanceText(balance, firm)}
                            </StyledBalance>
                            <StyledBalanceText>{getDisplayAccountText(balance)}</StyledBalanceText>
                          </div>
                          {
                            hasUnpaidInvoice && (!noPayment) && (
                              <PayNow onSubmit={onPayNow} text={'PAY BALANCE'} />
                            )
                          }
                        </Nav.Item>
                      )
                    }
                    
                    <NavDropdown id='user-dropdown' onClick={trackMixpanelEventUserName} title={`${user.email || 'demo@bill4time.com'}`}>
                      <NavDropdown.Item as={Link} onClick={trackMixpanelEventMyAccount} to={'/account-settings'}>{`My Account`}</NavDropdown.Item>
                      <NavDropdown.Item onClick={onClickLogout}>{`Logout`}</NavDropdown.Item>
                    </NavDropdown>
                  </Nav>
                )
                : (
                  <>
                    {
                      ( tokens && tokens.authToken && !tokens.refreshToken ) &&
                      ( location && location.pathname && R.includes('/invoice/', location.pathname) ) && 
                      disableBalances && (
                        <Nav.Item className="d-flex flex-row align-items-center mr-3">
                          <div className="text-md-center mr-2">
                            <StyledBalance className="m-0" max={30} mode="single">
                              {getDisplayAccountBalanceText(balance, firm)}
                            </StyledBalance>
                            <StyledBalanceText>
                              {getDisplayAccountText(balance)}
                            </StyledBalanceText>
                          </div>
                          {
                            !noPayment && (
                              <PayNow onSubmit={onPayNowNonLoggedIn} text={'PAY BALANCE'}/>
                            )
                          }
                        </Nav.Item>
                      )
                    }
                    {
                      location && !(location.pathname === '/login') &&
                      (
                        <Nav>
                          <NavLink activeClassName='selected-link' className='pr-0 my-4 my-md-0 style-link' to={'/login'}>{`Login`}</NavLink>
                        </Nav>
                      )
                    }
                  </>
                )
            }
          </NavbarCollapse>
        </StyledNavbar>
      </Container>
    </StyledContainer>
  );
};

export default CustomNavbarMenu;
