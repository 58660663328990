import { type PaymentBody, type PendingPayment, } from 'domain/payment';
import { type PaypalCreateOrderPayload, type PaypalPaymentPayload, } from 'state/payment/types';
import React, { useEffect, } from 'react';
import { Alert, Col, Row, Spinner, } from 'react-bootstrap';
import Feedback from 'react-bootstrap/Feedback';
import { type InvoicePaymentRouteParams, type PaymentSuccess, } from '../../types';
import { Colors, } from 'assets/index';
import { RequiredLabel } from './RequiredLabel';
import ErrorMsg from './ErrorMsg';
import styled from 'styled-components';

const StyledFormError = styled(Feedback)`
    color: ${Colors.redDanger};
    display: block;
`;

const FormField = styled.span`
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;

  iframe {
    border: 0 none transparent;
      height: 100%;
      vertical-align: middle;
      width: 100%;
  }
`;

type Props = {
    vgsForm: Object;
    error: Object;
};

const HeadnoteCreditCard = ({ vgsForm, error, vgsErrors }: Props) => {
    const vgsFieldsCss = {
        'font-size': '14px',
        'color': '#505d7d !important'
    };

    useEffect(() => {
        if (!vgsForm) {
            return;
        }

        // Create VGS Collect field for credit card name
        vgsForm.field('#cardholder_name', {
            type: 'text',
            name: 'cardholder_name',
            validations: ['required'],
            css: vgsFieldsCss
        });

        // Create VGS Collect field for credit card number
        vgsForm.field('#card_number', {
            type: 'card-number',
            name: 'card_number',
            successColor: '#4F8A10',
            errorColor: '#D8000C',
            validations: ['required', 'validCardNumber'],
            showCardIcon: true,
            css: vgsFieldsCss
        });

        // Create VGS Collect field for CVC
        vgsForm.field('#cvv_code', {
            type: 'card-security-code',
            name: 'cvv_code',
            validations: ['required', 'validCardSecurityCode'],
            css: vgsFieldsCss
        });

        // Create VGS Collect field for credit card expiration date
        vgsForm.field('#exp_date', {
            type: 'card-expiration-date',
            name: 'exp_date',
            yearLength: 2,
            placeholder: 'MM/YY',
            validations: ['required', 'validCardExpirationDate'],
            serializers: [
                { name: 'replace', options: { old: ' ', new: '' } }
            ],
            css: vgsFieldsCss
        });

        // Create VGS Collect field for credit card zip code
        vgsForm.field('#zip_code', {
            type: 'text',
            name: 'zip_code',
            validations: ['required', 'postal_code/INTL'],
            css: vgsFieldsCss,
            maxLength: 10
        });

    }, [vgsForm]);

    return (
        <>
            { <>
                <h4>{`Credit Card Payment`}</h4>
                <ErrorMsg error={error} />
                {!vgsForm ?
                    <Spinner animation="border" />
                    : <form>
                        <div className="form-group">
                            <label for="cardholder_name"><h6><RequiredLabel>Cardholder Name</RequiredLabel></h6></label>
                            <FormField id="cardholder_name" className="form-field" />
                            {
                                vgsErrors?.cardholder_name && (
                                    <StyledFormError type="invalid">
                                        {`Cardholder Name ${vgsErrors.cardholder_name.errorMessages[0]}`}
                                    </StyledFormError>
                                )
                            }
                        </div>
                        <div className="form-group">
                            <label for="card_number"><RequiredLabel>Card Number</RequiredLabel></label>
                            <FormField id="card_number" className="form-field" />
                            {
                                vgsErrors?.card_number && (
                                    <StyledFormError type="invalid">
                                        {`Card Number ${vgsErrors.card_number.errorMessages[0]}`}
                                    </StyledFormError>
                                )
                            }
                        </div>

                        <Row className='pb-3'>
                            <Col >
                                <div className="form-group">
                                    <label for="exp_date"><RequiredLabel>Expiration Date</RequiredLabel></label>
                                    <FormField id="exp_date" className="form-field" />
                                    {
                                        vgsErrors?.exp_date && (
                                            <StyledFormError type="invalid">
                                                {`Expiration Date ${vgsErrors?.exp_date.errorMessages[0]}`}
                                            </StyledFormError>
                                        )
                                    }
                                </div>
                            </Col>
                            <Col >
                                <div className="form-group">
                                    <label for="cvv_code"><RequiredLabel>CVV code</RequiredLabel></label>
                                    <FormField id="cvv_code" className="form-field" />
                                    {
                                        vgsErrors?.cvv_code && (
                                            <StyledFormError type="invalid">
                                                {`CVV Code ${vgsErrors?.cvv_code.errorMessages[0]}`}
                                            </StyledFormError>
                                        )
                                    }
                                </div>
                            </Col>
                            <Col >
                                <div className="form-group">
                                    <label for="zip_code"><RequiredLabel>Zip/Postal Code</RequiredLabel></label>
                                    <FormField id="zip_code" className="form-field" />
                                    {
                                        vgsErrors?.zip_code && (
                                            <StyledFormError>
                                                {`Zip/Postal Code ${vgsErrors?.zip_code.errorMessages[0]}`}
                                            </StyledFormError>
                                        )
                                    }
                                </div>
                            </Col>
                        </Row>
                    </form>
                }
            </>}
        </>
    );
};

export default React.memo(HeadnoteCreditCard);
